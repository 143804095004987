import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { slug2path } from "../helpers/slug2path";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Link from "../helpers/Link";
import { languageMerge } from "../helpers/languageMerge";
import { useTranslation } from "react-i18next";

import Layout from "../components/Layout";
// import Footer from "../components/sections/Footer";

const Wrapper = styled.div`
  padding: 0 10vw;

  & > h1 {
    margin: 30px 0;
    font-size: 40px;
  }
  & > ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2vw;
    margin-bottom: 30vh;

    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 960px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 420px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
    }

    & li {
      cursor: pointer;

      & h2 {
        margin-top: 0.5em;
        text-transform: uppercase;
        font-weight: bold;
      }

      & h3 {
        margin-bottom: -0.3em;
      }

      & picture,
      & img {
        transition: opacity 0.3s ease;
      }
      &:hover picture,
      &:hover img {
        opacity: 0.6;
      }
    }
  }
`;

const ProjectPage = ({ data, ...meta }) => {
  const { i18n } = useTranslation();

  data = languageMerge(data, i18n.language).allMarkdownRemark.edges.map(
    ({ node }) => ({ ...node, ...node.frontmatter, frontmatter: null })
  );

  return (
    <Layout meta={meta}>
      <Wrapper>
        <h1>Projekty</h1>
        <br />
        <ul>
          {data
            .sort((a, b) => new Date(b.dateOrder) - new Date(a.dateOrder))
            .map((project) => (
              <li key={project.fields.slug}>
                <Link to={slug2path(project.fields.slug)}>
                  <GatsbyImage
                    image={
                      project.featuredthumbnail
                        ? getImage(project.featuredthumbnail)
                        : getImage(project.featuredimage)
                    }
                    alt={project.title}
                  />
                  <h2>{project.slug}</h2>
                  <h3>{project.title}</h3>
                  <h4>{project.location}</h4>
                </Link>
              </li>
            ))}
        </ul>
      </Wrapper>
    </Layout>
  );
};

export default ProjectPage;

export const pageQuery = graphql`
  query ProjectsBrowsePage($language: String!) {
    allMarkdownRemark(
      filter: { fields: { slug: { ne: "/atelier/" } } }
      sort: { fields: frontmatter___dateOrder, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            location
            date
            dateOrder
            tags
            slug
            featuredthumbnail {
              base
              url: publicURL
              childImageSharp {
                gatsbyImageData(width: 512, height: 512)
              }
            }
            featuredimage {
              base
              url: publicURL
              childImageSharp {
                gatsbyImageData(width: 512, height: 512)
              }
            }
          }
        }
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
